import React from 'react'
import PropTypes from 'prop-types'

export default function SearchIcon({ color }) {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0012 6C8.936 6 6.45117 8.48486 6.45117 11.55C6.45117 14.6152 8.93598 17.1 12.0012 17.1C15.0664 17.1 17.5512 14.6152 17.5512 11.55C17.5512 8.48486 15.0663 6 12.0012 6ZM4.95117 11.55C4.95117 7.65645 8.10756 4.5 12.0012 4.5C15.8948 4.5 19.0512 7.65645 19.0512 11.55C19.0512 15.4437 15.8948 18.6 12.0012 18.6C8.10758 18.6 4.95117 15.4437 4.95117 11.55Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.871 15.4208C16.1639 15.1279 16.6388 15.128 16.9317 15.4208L20.2317 18.7209C20.5246 19.0138 20.5246 19.4887 20.2317 19.7816C19.9388 20.0744 19.4639 20.0744 19.171 19.7815L15.871 16.4815C15.5781 16.1886 15.5781 15.7137 15.871 15.4208Z"
      fill={color}
    />
  </svg>
  )
}

SearchIcon.propTypes = {
  color: PropTypes.string
}
SearchIcon.defaultProps = {
  color: '#A39EB8'
}
