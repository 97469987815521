import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Box, Typography } from '@material-ui/core';
import TimeIcon from '../atoms/icons/TimeIcon';

const useStyles = makeStyles((theme) => ({
  searchResults: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '6px 18px',
    zIndex: 1,
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.primary.soft,
      '& .MuiTypography-body1': {
        color: theme.palette.primary.main
      }
    },
    '&:last-child': {
      borderRadius: ' 0px 0px 12px 12px',
    }
  },
  wrapperResult: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '& div:nth-child(1)': {
      width: 34
    }
  },
  icon: {
    // height: '100%',
    display: 'flex',
  },
  titleDescription: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '90%',
  },

  title: {
    color: theme.palette.grey[700],
    marginLeft: '5px'
  },
  iconTitle: {
    color: theme.palette.grey[700],
    padding: '0px 3px',
    fontSize: 20
  },
  description: {
    color: theme.palette.grey[500],
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '90%',
  },
  inputFeedback: {
    borderTop: `1px solid ${theme.palette.gray.lightGray}`,
    width: '100%'
  },
  wrapperMessage: {
    width: '100%',
    padding: '6px 18px',
    zIndex: 1,
    borderRadius: ' 0px 0px 12px 12px',
  }

}));

export default function InputItemFeedback({ cnaes, selectCnae, inputLength, loading, message }) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <Box className={classes.inputFeedback}>
        {loading && (
          <Box style={{ padding: '6px 18px' }}>
             <Typography className={classes.description}>
            Buscando...
          </Typography>
          </Box>
        )}
        {cnaes.length <= 0 && (
          <Box className={classes.wrapperMessage}>
                <Typography className={classes.description}>
                  {message}
                </Typography>
              </Box>
        )}
        {cnaes.length > 0 && (
          <>
          {cnaes.map(cnae => (
            <Box key={cnae._id} className={classes.searchResults} onClick={() => selectCnae(cnae)}>
              <Box className={classes.wrapperResult}>
                <Box className={classes.icon}>
                  <TimeIcon color={theme.palette.primary.main} />
                </Box>
                <Box className={classes.titleDescription}>
                  <Typography variant="body1" className={classes.title}>
                    {cnae?.subclasse}
                  </Typography>
                  <Typography variant="body1" className={classes.iconTitle}>
                    {' · '}
                  </Typography>
                  <Typography className={classes.description}>
                    {cnae?.descricao}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
          </>
        )}
      </Box>
    </>
  );
}

InputItemFeedback.propTypes = {
  cnaes: PropTypes.array.isRequired,
  selectCnae: PropTypes.func.isRequired,
  inputLength: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired
};
InputItemFeedback.defaultProps = {
  cnaes: [],
  inputLength: 0,
  message: ''
};
