import { makeStyles } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: '36px'
  }
}));

export default function Spacing() {
  const classes = useStyles();

  return (
    <div className={classes.container}/>
  )
}
