import React from 'react';

import { ThemeProvider, createTheme } from '@material-ui/core/styles'
import { CnaeContextProvider } from '../src/contexts/CnaeContext';
import { DrawerContextProvider } from '../src/contexts/DrawerContext'

export function wrapRootElement({ element }) {
  const theme = createTheme({
    overrides: {
      MuiButton: {
        contained: {
          padding: '10px 25px',
          '&.Mui-disabled': {
            color: '#fff',
          },
        },
        outlined: {
          padding: '10px 25px',
          border: '2px solid',
        },
        text: {
          padding: '10px 25px',
        },
      },
      MuiLinearProgress: {
        root: {
          height: 5,
          borderRadius: 5,
        },
        colorPrimary: {
          backgroundColor: '#cccbd2',
        },
        bar: {
          borderRadius: 5,
        },
      },
      MuiMenu: {
        paper: {
          borderRadius: 15,
          boxShadow: '0px 0px 0px -3px rgba(0,0,0,0.2), 0px 5px 5px 1px rgba(0,0,0,0.14), 0px 0px 5px 0px rgba(0,0,0,0.12)',
        },
      },
      MuiInputBase: {
        root: {
          paddingLeft: 10,
          paddingRight: 10,

          '& .MuiSelect-root:focus': {
            backgroundColor: 'rgba(0,0,0,0)',
          },
        },
      },
      MuiOutlinedInput: {
        root: {
          '&:hover': {
            borderColor: '#573da8 !important',
          },
        },
      },
      MuiInputLabel: {
        outlined: {
          transform: 'translate(24px, 20px) scale(1)',
        },
      },
      MuiAlert: {
        root: {
          borderRadius: 0,
        },
      },
      MuiCard: {
        root: {
          borderRadius: 15,
          boxShadow: '0 5px 10px 0 rgb(45 45 119 / 0.10)',
        },
      },
      MuiCardContent: {
        root: {
          padding: '36px 24px',
        },
      },
      MuiPaper: {
        rounded: {
          borderRadius: 15,
        },
      },
      MuiAutocomplete: {
        input: {
          paddingLeft: '15px !important',
        },
      },
      MuiTableCell: {
        head: {
          fontWeight: 'bold',
        },
      },
      MuiLink: {
        root: {
          '&:hover': {
            cursor: 'pointer',
          },
        },
      },
    },
    palette: {
      primary: {
        main: '#573da8',
        lightest: '#DED6F8',
        alpha: 'rgba(87, 61, 168, 0.25)',
        dark: '#3A2870',
        soft: '#F5F2FF',
      },
      secondary: {
        main: '#f17700',
        lightest: '#FFEBDB',
        contrastText: '#ffffff',
      },
      error: {
        main: '#d62046',
        lightest: '#FFD1DA',
        alpha: 'rgba(214, 32, 70, 0.25)',
        light: '#F7C7D1',
        soft: '#FFE2E8',
      },
      warning: {
        main: '#FF8F15',
        lightest: '#FFEBDB',
        alpha: 'rgba(241, 119, 0, 0.25)',
        soft: '#FFEEDD',
      },
      info: {
        main: '#44a0db',
        lightest: '#BCE5FF',
        alpha: 'rgba(68, 160, 219, 0.2)',
        soft: '#EFF9FF',
      },
      success: {
        main: '#48ae9f',
        lightest: '#B5E2DB',
        alpha: 'rgba(172, 221, 216, 0.5)',
        light: '#CEF0EB',
        soft: '#E6FFFB',
      },
      text: {
        primary: '#383743',
        lightest: '#C9C9C9',
      },
      danger: {
        main: '#000',
      },
      white: {
        main: '#F4F3F1',
        almost: '#F7F7FA',
      },
      gray: {
        soft: '#F3F4F6',
        lightGray: '#CDCADE',
        main: '#A39EB8',
        midGray: '#676482',
        darkGray: '#36344B',
      },
      black: {
        main: '#1A1738',
      },
      chipGrey: {
        main: '#A39EB8',
        alpha: '#F7F7FA',
      },
      action: {
        disabledBackground: '#cccbd2',
      },
      midGrey: {
        main: '#66647A',
      },
      darkGrey: {
        main: '#36344B',
      },
      tonalOffset: 0.1,
    },
    shape: {
      borderRadius: 50,
    },
    typography: {
      fontFamily: ['Nunito'].join(','),
      h1: {
        fontSize: 69,
        fontWeight: 'bold',
        color: '#383743',
      },
      h2: {
        fontSize: 53,
        fontWeight: 300,
        color: '#383743',
      },
      h3: {
        fontSize: 40,
        fontWeight: 300,
        color: '#383743',
      },
      h4: {
        fontSize: 31,
        color: '#383743',
      },
      h5: {
        fontSize: 24,
        color: '#383743',
      },
      h6: {
        fontSize: 20,
        fontWeight: 400,
        color: '#383743',
      },
      subtitle1: {
        fontSize: 16,
        letterSpacing: 1.04,
        lineHeight: 1.25,
      },
      subtitle2: {
        fontSize: 14,
        letterSpacing: 0.58,
        fontWeight: 700,
      },
      body1: {
        fontSize: 16,
        lineHeight: 1.51,
        color: '#66647a',
      },
      body2: {
        fontSize: 14,
        lineHeight: 1.51,
        fontWeight: 300,
        color: '#383743',
      },
      button: {
        fontSize: 14,
        letterSpacing: 0.6,
        fontWeight: 600,
        textTransform: '',
      },
      caption: {
        fontSize: 14,
        fontWeight: 300,
      },
      overline: {
        fontSize: 12,
        fontWeight: 400,
        lineHeight: 2,
        textTransform: 'uppercase',
        marginTop: '10px',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CnaeContextProvider>
        <DrawerContextProvider>
          {element}
        </DrawerContextProvider>
      </CnaeContextProvider>
    </ThemeProvider>
  );
}
