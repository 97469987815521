import React from 'react';
import { Typography, makeStyles, Grid, Box } from '@material-ui/core';
import RedirectLink from '../atoms/RedirectLink';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  links: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.gray.midGray,

    '&:hover': {
      textDecoration: 'underline'
    }
  },
  titleTopic: {
    letterSpacing: '0.065em',
    lineHeight: '22px'
  }
}));

export default function FooterMenuDesktop({ links }) {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {links.map(link => (
        <Grid className={classes.links} key={link.key}>
          <Typography variant="subtitle2" className={classes.titleTopic}>{link.title}</Typography>

          {link.items.map(item => (
            <RedirectLink key={item.key} className={classes.link} href={item.link} toNewTab>{item.title}</RedirectLink>
          ))}
        </Grid>
      ))}
    </Box>
  );
};

FooterMenuDesktop.propTypes = {
  links: PropTypes.array
};
