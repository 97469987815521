import React from 'react';
import {
  Box,
  MenuItem,
  makeStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import MenuPopper from '../atoms/MenuPopper';
import KeyIcon from '../atoms/icons/KeyIcon';
import HomeIcon from '../atoms/icons/HomeIcon';
import SwitchIcon from '../atoms/icons/SwitchIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    '& a': {
      height: '50px',
      display: 'flex',
      columnGap: '15px',
    },
  },
  switchProcess: {
    color: theme.palette.success.main
  },
  openingProcess: {
    color: theme.palette.info.main
  },
  divider: {
    width: '89%',
    height: '1px',
    backgroundColor: theme.palette.action.disabledBackground,
    margin: '0 16px'
  },
}));

const items = [
  {
    component: 'a',
    href: 'https://app.conube.com.br/',
    target: '_blank',
    icon: <HomeIcon />,
    text: 'Acessar plataforma',
    className: ''
  },
  {
    component: 'a',
    href: 'https://abrirempresa.conube.com.br/login',
    target: '_blank',
    icon: <KeyIcon />,
    text: 'Processo de abertura',
    className: 'openingProcess',
  },
  {
    component: 'a',
    href: 'https://trocarcontador.conube.com.br/login',
    target: '_blank',
    icon: <SwitchIcon />,
    text: 'Processo trocar de contador',
    className: 'switchProcess',
  }
]

export default function HeaderDropDownMenu({ menuAnchor, setMenuAnchor }) {
  const classes = useStyles();

  return (
    <MenuPopper menuAnchor={menuAnchor} setMenuAnchor={setMenuAnchor} className={classes.root}>
      {items.map((item, i) => (
        <MenuItem
          component={item.component}
          href={item.href}
          target={item.target}
          key={item.text.length - i}
          className={classes[item.className]}
        >
         {item.icon}
         {item.text}
       </MenuItem>
      ))}
    </MenuPopper>
  )
}

HeaderDropDownMenu.propTypes = {
  menuAnchor: PropTypes.string.isRequired,
  setMenuAnchor: PropTypes.string.isRequired
}
