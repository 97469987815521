import { Box } from '@material-ui/core';
import React from 'react';
import ContainerLimit from '../atoms/ContainerLimit';
import Spacing from '../atoms/Spacing';
import Header from '../templates/Header';
import Hero from '../templates/Hero';
import PropTypes from 'prop-types';
import Footer from '../organisms/Footer';
import BigSpacer from '../atoms/BigSpacer';

export default function Layout({ children }) {
  return (
    <Box>
      <Header />
      <Hero />
      <ContainerLimit>
        <Spacing />
        <Box>
          {children}
        </Box>
        <BigSpacer />
        <Footer />
      </ContainerLimit>
    </Box>
  );
}

Layout.propTypes = {
  children: PropTypes.elementType.isRequired
};
