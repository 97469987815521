import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export default function ShowInMobile({ children, breakpoint }) {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(window.innerWidth);
  })

  if (width <= breakpoint) {
    return <></>
  } else {
    return (
      children
    )
  }
}

ShowInMobile.propTypes = {
  children: PropTypes.element.isRequired,
  breakpoint: PropTypes.number.isRequired
}
