import { Box, Typography, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import React from 'react'
import StyledButton from './StyledButton';
import PropTypes from 'prop-types';

export default function TextCtaDrawer({
  title,
  description,
  hasButton,
  variantTitle,
  onClick,
  type,
  buttonText,
  noMargin
}) {
  const theme = useTheme();
  const types = {
    none: {
      color: 'inherit',
      fill: 'inherit'
    },
    openCompany: {
      color: theme.palette.info.main,
      fill: theme.palette.info.main,
    },
    switchAccount: {
      color: theme.palette.success.main,
      fill: theme.palette.success.main,
    }
  }

  const { color, fill } = type ? types[type] : null

  const useStyles = makeStyles(() => ({
    container: {
      margin: noMargin ? '' : '50px 0',
      '& .MuiTypography-root, & .MuiButton-label svg path': {
        color,
        fill
      }
    },
  }));

  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography variant={variantTitle} component="h4">{title}</Typography>
      <Typography variant="body1" classes={hasButton ? '' : classes.description}>
        {description}
      </Typography>
      {hasButton && (
        <StyledButton
        text={buttonText}
        onClick={onClick}
        type={type}
        height="35px"
      />
      )}
  </Box>
  )
}

TextCtaDrawer.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  hasButton: PropTypes.bool.isRequired,
  variantTitle: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  noMargin: PropTypes.string.isRequired
}

TextCtaDrawer.defaultProps = {
  title: '',
  description: '',
  hasButton: false,
  variantTitle: 'h6',
  onClick: () => {},
  type: '',
  buttonText: '',
  noMargin: false
}
