import { useState } from 'react';
import { api } from '../services/api';

export default function useFetchCnae() {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  async function fetch(params) {
    setLoading(true);
    try {
      const { data } = await api.get(`${process.env.GATSBY_BASE_URL}/util/getCnaes`, {
        params
      });
      return data;
    } catch (e) {
      setError(e);
      return [];
    } finally {
      setLoading(false);
    }
  }

  async function getById(id) {
    setLoading(true);
    try {
      const { data } = await api.get(`${process.env.GATSBY_BASE_URL}/util/getCnaeById`, {
        params: {
          id
        }
      });

      return data;
    } catch (err) {
      return [];
    } finally {
      setLoading(false);
    }
  }

  return {
    error,
    loading,
    fetch,
    getById
  };
}
