import React from 'react';
import PropTypes from 'prop-types';

export default function ArrowIcon({ color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12.763" height="7.484" viewBox="0 0 12.763 7.484">
      <path data-name="Caminho 7610" d="M1.042,141.045l.186.186a.316.316,0,0,0,.448,0l5.155-5.155,5.155,5.155a.316.316,0,0,0,.448,0l.186-.186a.316.316,0,0,0,0-.447l-5.565-5.565a.316.316,0,0,0-.448,0L1.042,140.6A.316.316,0,0,0,1.042,141.045Z" transform="translate(13.213 141.874) rotate(180)" fill="none" stroke={color} strokeWidth="1"/>
    </svg>
  )
}

ArrowIcon.propTypes = {
  color: PropTypes.string
}

ArrowIcon.defaultProps = {
  color: '#66647A'
}
