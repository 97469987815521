import { Box, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import CenteredLoading from '../atoms/CenteredLoading';
import ArrowLeftIcon from '../atoms/icons/ArrowLeftIcon';
import StyledDrawer from '../molecules/StyledDrawer';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import CreateAccountOptions from '../molecules/CreateAccountOptions';
import OpenCompanyFormDrawer from '../molecules/OpenCompanyFormDrawer';
import SwitchAccountantFormDrawer from '../molecules/SwitchAccountantFormDrawer';
import UseDrawerContext from '../../contexts/DrawerContext';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDrawer-paper': {
      width: '550px',
      margin: '10px',
      height: '98%',
      borderRadius: '25px !important',

      '@media screen and (max-height: 800px)': {
        height: '95%',
      },

      '@media screen and (max-width: 959px)': {
        width: '90%',
        height: '100vh',
        margin: 0,
        borderRadius: '0 !important',

        '& .react-swipeable-view-container': {
          paddingBottom: '200px',
        },
      },
    },

    '& .loaded': {
      opacity: '1',
      transition: '0.2s',
    },

    '& .loading': {
      opacity: '0.5',
      transition: '0.2s',
    },
  },
  top: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    marginBottom: '15px',

    '@media screen and (max-width: 430px)': {
      alignItems: 'flex-start',
    },
  },
}));

export default function CreateAccountDrawer() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [steps, setSteps] = useState(0);
  const { open, setOpen, process, setProcess } = UseDrawerContext();

  useEffect(() => {
    if (process === 'abrir-empresa') setSteps(1);
  }, [process]);

  function handleNextStep(chosenProcess) {
    setProcess(chosenProcess);
    setSteps(1);
  };

  function handleReturnStep() {
    if (process === 'default') {
      setSteps(0);
    } else {
      setProcess('default');
      setSteps(0);
    }
  };

  function handleCloseDrawer() {
    setOpen(false);
    setSteps(0);
    setProcess('default');
  };

  useEffect(() => {
    if (process !== 'default') {
      setProcess(process);
    }
  }, [process]);

  return (
    <StyledDrawer open={open} setOpen={handleCloseDrawer} className={classes.root} disabled={isLoading}>
      <SwipeableViews index={steps} onChangeIndex={setSteps}>
        <div>
          <CreateAccountOptions handleNextStep={handleNextStep} />
        </div>
        <div className={isLoading ? 'loading' : 'loaded'}>
          <Box className={classes.top}>
            <IconButton color="primary" onClick={handleReturnStep} disabled={isLoading}>
              <ArrowLeftIcon />
            </IconButton>
            <Typography variant="h5">Certo, vamos iniciar o cadastro</Typography>
          </Box>

          {process === 'abrir-empresa' && (
            <OpenCompanyFormDrawer
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          )}

          {process === 'trocar-contador' && (
            <SwitchAccountantFormDrawer
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          )}
        </div>
      </SwipeableViews>
    {isLoading && <CenteredLoading />}
  </StyledDrawer>
  );
}

CreateAccountDrawer.propTypes = {
  process: PropTypes.string.isRequired,
  createAccount: PropTypes.bool.isRequired,
  setCreateAccount: PropTypes.func.isRequired
};
