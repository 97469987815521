import React from 'react';
import PropTypes from 'prop-types';

export default function LinkedinIcon({ color }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.5 3.75C5.42921 3.75 3.75 5.42921 3.75 7.5V16.504C3.75 18.5728 5.42721 20.25 7.496 20.25H16.504C18.5729 20.25 20.25 18.5727 20.25 16.505V7.496C20.25 5.42721 18.5728 3.75 16.504 3.75H7.5ZM7.5 2.25C4.60079 2.25 2.25 4.60079 2.25 7.5V16.504C2.25 19.4012 4.59879 21.75 7.496 21.75H16.504C19.4011 21.75 21.75 19.4013 21.75 16.505V7.496C21.75 4.59879 19.4012 2.25 16.504 2.25H7.5Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.22422 10.3501C8.63843 10.3501 8.97422 10.6859 8.97422 11.1001V16.5001C8.97422 16.9143 8.63843 17.2501 8.22422 17.2501C7.81 17.2501 7.47422 16.9143 7.47422 16.5001V11.1001C7.47422 10.6859 7.81 10.3501 8.22422 10.3501Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M13.969 11.85C13.1402 11.85 12.469 12.5212 12.469 13.35V16.5C12.469 16.9142 12.1332 17.25 11.719 17.25C11.3048 17.25 10.969 16.9142 10.969 16.5V13.35C10.969 11.6928 12.3118 10.35 13.969 10.35C15.6262 10.35 16.969 11.6928 16.969 13.35V16.5C16.969 16.9142 16.6332 17.25 16.219 17.25C15.8048 17.25 15.469 16.9142 15.469 16.5V13.35C15.469 12.5212 14.7978 11.85 13.969 11.85Z" fill={color}/>
      <path d="M8.97421 8.55677C8.97421 8.97098 8.63843 9.30677 8.22421 9.30677C7.81 9.30677 7.47421 8.97098 7.47421 8.55677C7.47421 8.14255 7.81 7.80677 8.22421 7.80677C8.63843 7.80677 8.97421 8.14255 8.97421 8.55677Z" fill={color}/>
    </svg>

  )
}

LinkedinIcon.propTypes = {
  color: PropTypes.string
}

LinkedinIcon.defaultProps = {
  color: '#676482'
}
