import React from 'react'
import PropTypes from 'prop-types';

export default function KeyIcon({ color }) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M1.51455 4.83148C3.81479 1.95617 8.18561 1.95617 10.4859 4.83148C11.8795 6.57355 12.0939 9.08975 11.071 11.1354C11.0689 11.1397 11.0667 11.144 11.0644 11.1483C11.0625 11.1519 11.0606 11.1555 11.0586 11.1591C10.474 12.2309 9.7026 12.9918 8.7502 13.4817V17C8.7502 18.5142 7.51442 19.75 6.0002 19.75C4.48599 19.75 3.2502 18.5142 3.2502 17V13.4817C2.29781 12.9918 1.5264 12.2309 0.94178 11.1591C0.937506 11.1513 0.933373 11.1434 0.929382 11.1354C-0.0934501 9.08975 0.120892 6.57355 1.51455 4.83148ZM9.31455 5.76852C7.6148 3.64383 4.38561 3.64383 2.68585 5.76852C1.68208 7.02324 1.49481 8.90065 2.2651 10.4527C2.78592 11.4022 3.45416 11.9738 4.27875 12.3036C4.56349 12.4175 4.7502 12.6933 4.7502 13V17C4.7502 17.6858 5.31442 18.25 6.0002 18.25C6.68599 18.25 7.2502 17.6858 7.2502 17V13C7.2502 12.6933 7.43692 12.4175 7.72166 12.3036C8.54625 11.9738 9.21448 11.4022 9.73531 10.4527C10.5056 8.90065 10.3183 7.02324 9.31455 5.76852Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M5.24488 7.54467C5.48514 7.30441 5.7846 7.25 6.0002 7.25C6.21581 7.25 6.51527 7.30441 6.75553 7.54467C6.99579 7.78492 7.05021 8.08439 7.05021 8.3C7.05021 8.67881 6.85261 8.95355 6.65622 9.11065C6.48359 9.24876 6.24763 9.35 6.0002 9.35C5.7846 9.35 5.48514 9.29559 5.24488 9.05533C5.00462 8.81508 4.9502 8.51561 4.9502 8.3C4.9502 8.08439 5.00462 7.78492 5.24488 7.54467ZM6.30553 8.60533C6.44579 8.46508 6.4502 8.31561 6.4502 8.3C6.4502 8.28439 6.44579 8.13492 6.30553 7.99467C6.16527 7.85441 6.01581 7.85 6.0002 7.85C5.91214 7.85 5.85163 7.8692 5.82227 7.88038C5.78743 7.89366 5.75284 7.91242 5.71919 7.93935C5.6478 7.99646 5.55021 8.12119 5.55021 8.3C5.55021 8.31561 5.55462 8.46508 5.69488 8.60533C5.83514 8.74559 5.9846 8.75 6.0002 8.75C6.01581 8.75 6.16527 8.74559 6.30553 8.60533Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M5.3502 3.6C5.3502 1.80477 6.76726 0.25 8.7002 0.25C10.4954 0.25 12.0502 1.66706 12.0502 3.6C12.0502 4.01421 11.7144 4.35 11.3002 4.35C10.886 4.35 10.5502 4.01421 10.5502 3.6C10.5502 2.53294 9.70497 1.75 8.7002 1.75C7.63314 1.75 6.8502 2.59523 6.8502 3.6V8C6.8502 8.41421 6.51442 8.75 6.1002 8.75C5.68599 8.75 5.3502 8.41421 5.3502 8V3.6Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.1699 4.73033C11.6628 4.22322 10.9376 4.22322 10.4305 4.73033L9.93054 5.23033C9.63764 5.52322 9.16277 5.52322 8.86988 5.23033C8.57698 4.93744 8.57698 4.46256 8.86988 4.16967L9.36988 3.66967C10.4628 2.57678 12.1376 2.57678 13.2305 3.66967L18.9305 9.36967C20.0234 10.4626 20.0234 12.1374 18.9305 13.2303L16.3305 15.8303C15.2376 16.9232 13.5628 16.9232 12.4699 15.8303L8.96987 12.3303C8.67698 12.0374 8.67698 11.5626 8.96987 11.2697C9.26277 10.9768 9.73764 10.9768 10.0305 11.2697L13.5305 14.7697C14.0376 15.2768 14.7628 15.2768 15.2699 14.7697L17.8699 12.1697C18.377 11.6626 18.377 10.9374 17.8699 10.4303L12.1699 4.73033Z" fill={color}/>
    </svg>

  )
}

KeyIcon.propTypes = {
  color: PropTypes.string,
}

KeyIcon.defaultProps = {
  color: '#44A0DB'
}
