import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import ArrowRightIcon from '../atoms/icons/ArrowRightIcon';
import StyledButton from '../atoms/StyledButton';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/styles';
import TextCtaDrawer from '../atoms/TextCtaDrawer';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '70%',

    '& .MuiTypography-h5, & .MuiTypography-h6': {
      marginBottom: '10px',
    },

    '& .MuiButton-root': {
      marginTop: '15px',

      '& .MuiButton-label svg': {
        margin: '0 0 1px 10px',
      },
    },

    '@media screen and (max-width: 959px)': {
      width: '100%',
    },
  },
}));

export default function CreateAccountOptions({ handleNextStep }) {
  const classes = useStyles();

  function renderButtonText(isOpenCompany) {
    return (
      <>
      {isOpenCompany ? 'Abrir empresa' : 'Trocar de contador'}
      <ArrowRightIcon />
    </>
    )
  }

  return (
    <Box className={classes.root}>

      <TextCtaDrawer
        title={'Criar conta'}
        description={'Mude para a Contabilidade Online e fique livre de processos burocráticos, deixe tudo com a gente!'}
        variantTitle={'h5'}
        type={'none'}
        noMargin
      />
      <TextCtaDrawer
        title={'Estou abrindo empresa'}
        description={'Vamos ajudá-lo a realizar esse sonho, nós cuidaremos de toda burocracia e papelada para você.'}
        type={'openCompany'}
        hasButton
        onClick={() => handleNextStep('abrir-empresa')}
        buttonText={renderButtonText(true)}
      />

      <TextCtaDrawer
        title={'Quero trocar de contador'}
        description={'Vamos ajudá-lo a realizar esse sonho, nós cuidaremos de toda burocracia e papelada para você.'}
        hasButton
        onClick={() => handleNextStep('trocar-contador')}
        buttonText={renderButtonText()}
        type={'switchAccount'}
      />
    </Box>
  )
}

CreateAccountOptions.propTypes = {
  handleNextStep: PropTypes.func.isRequired,
};
