import React from 'react';
import PropTypes from 'prop-types';

export default function SpotifyIcon({ color }) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.60938 7.13595C8.43837 6.00295 13.4354 6.78695 15.7644 8.25995" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5.06934 10.054C8.44234 9.00298 12.4253 9.75599 14.6283 11.173" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5.3252 12.7559C9.0782 11.9139 11.6842 12.5049 13.6542 13.6869" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5.5 1H14.504C16.987 1 19 3.013 19 5.496V14.505C19 16.987 16.987 19 14.504 19H5.496C3.013 19 1 16.987 1 14.504V5.5C1 3.015 3.015 1 5.5 1V1Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  )
}

SpotifyIcon.propTypes = {
  color: PropTypes.string
}

SpotifyIcon.defaultProps = {
  color: '#676482'
}
