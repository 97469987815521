import React from 'react';
import {
  Popper,
  ClickAwayListener,
  Grow,
  Paper,
  MenuList
} from '@material-ui/core';
import PropTypes from 'prop-types';

export default function MenuPopper({ menuAnchor, setMenuAnchor, children, ...rest }) {
  function handleClose(event) {
    if (!(menuAnchor && menuAnchor.current && menuAnchor.current.contains(event.target))) {
      setMenuAnchor(null);
    }
  }

  return (
    <Popper
      open={!!menuAnchor}
      anchorEl={menuAnchor}
      role={undefined}
      transition
      disablePortal
      {...rest}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList autoFocusItem={!!menuAnchor}>
                {children}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

MenuPopper.propTypes = {
  menuAnchor: PropTypes.object.isRequired,
  setMenuAnchor: PropTypes.func.isRequired,
  children: PropTypes.elementType.isRequired,
  rest: PropTypes.object.isRequired
};

MenuPopper.defaultProps = {
  menuAnchor: null
};
