import { makeStyles, useTheme } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, ClickAwayListener, IconButton } from '@material-ui/core';
import SearchIcon from '../atoms/icons/SearchIcon';
import InputItemFeedback from '../molecules/InputItemFeedback';
import useFetchCnae from '../../customHooks/UseFetchCnae';
import { navigate } from 'gatsby';
import CloseIcon from '../atoms/icons/CloseIcon';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    width: '100%',
    zIndex: -1,
    '& .MuiIconButton-root': {
      borderRadius: '0px !important'
    }
  },
  wrapper: {
    background: 'white',
    borderRadius: '12px',
    boxShadow: '0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31)',
    width: '100%'
  },
  containerInput: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '16px'
  },
  loadingClose: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  searchButton: {
    paddingLeft: '16px',
    display: 'flex',
    alignItems: 'center'
  },
  line: {
    content: '""',
    width: 13,
    height: 25,
    borderLeft: `0.6px solid ${theme.palette.gray.main}`,
  },
  input: {
    border: 'none',
    outline: 'none',
    height: '100%',
    width: '100%',
    color: theme.palette.gray.darkGray,
    fontSize: '16px',
    '&::placeholder': {
      color: theme.palette.gray.main,
      fontSize: '16px'
    }
  },
}));

export default function AutoCompleteInput() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [result, setResult] = useState([]);
  const [message, setMessage] = useState('');

  const { fetch } = useFetchCnae();

  useEffect(() => {
    let timer = null;
    if (inputValue.length < 3) {
      setLoading(false);
      setMessage('Digite pelo menos três caracteres para começar a buscar');
    } else {
      setLoading(true);
      timer = searchString();
    }

    return () => {
      clearTimeout(timer);
    };
  }, [inputValue]);

  function searchString() {
    return setTimeout(async () => {
      const data = await fetch({ search: inputValue, limit: 5 });
      setResult(data?.cnaes || []);
      if (data.length < 1 && inputValue > 3) {
        setMessage('Não encontramos nenhum CNAE de acordo com a sua busca.');
      }
      setLoading(false);
    }, 300);
  }

  function search() {
    setOpen(false);
    navigate(`/search?query=${inputValue}`);
  }

  function onSubmit(e) {
    e.preventDefault();
    setOpen(false);
    navigate(`/search?query=${inputValue}`);
  }

  function selectCnae(cnae) {
    setOpen(false);
    navigate(`/cnae/${cnae._id}`, { state: { cnae } });
  }

  function clear() {
    setInputValue('');
    setResult([]);
  }

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <form className={classes.container} onSubmit={(e) => onSubmit(e)}>
        <Box className={classes.wrapper}>

          <Box className={classes.containerInput}>
            <input
              type={'text'}
              placeholder={'Busque por código ou atividade'}
              onChange={(e) => setInputValue(e.target.value)}
              value={inputValue}
              className={classes.input}
              onFocus={() => setOpen(!open)}
              />
            <Box className={classes.loadingClose}>
              <Box className={classes.loader}>
                {loading && <CircularProgress size={24} />}
                {!loading && inputValue.length >= 1 && (
                  <IconButton size="small" onClick={() => clear()}>
                    <CloseIcon />
                  </IconButton>
                )}
              </Box>
              <Box className={classes.searchButton}>
                <Box className={classes.line} />
                <IconButton edge={'start'} size="medium" onClick={() => search()}>
                  <SearchIcon color={theme.palette.primary.main} />
                </IconButton>
              </Box>
            </Box>
          </Box>
          {open && (
            <InputItemFeedback
              cnaes={result}
              inputLength={inputValue.length}
              selectCnae={selectCnae}
              loading={loading}
              message={message}
            />
          )}
        </Box>
      </form>
    </ClickAwayListener>
  );
}
