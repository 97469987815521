import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  makeStyles,
} from '@material-ui/core';
import ArrowIcon from '../atoms/icons/ArrowIcon';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  list: {
    padding: 0,

    '& .MuiListItem-button': {
      borderRadius: '10px',
    },
  },
  name: {
    '& .MuiTypography-root': {
      color: `${theme.palette.primary.secondary} !important`,
      fontWeight: 'bold',
    },

    '& .MuiListItemIcon-root': {
      justifyContent: 'flex-end',

      '& svg path': {
        stroke: theme.palette.primary.secondary,
      },
    },

    '& .up svg': {
      transform: 'rotate(180deg)',
      transition: '0.1s',
    },

    '& .down svg': {
      transform: 'rotate(0deg)',
      transition: '0.1s',
    },
  },
  item: {
    paddingLeft: '30px',
  },
}))

export default function FooterMenuMobile({ links }) {
  const classes = useStyles();

  const [isCollapseOpen, setIsCollapseOpen] = useState('');

  function handleClick(value) {
    isCollapseOpen ? setIsCollapseOpen('') : setIsCollapseOpen(value)
  }

  return (
    <>
      <List className={classes.list}>
        {links.map((link) => (
         <>
          <ListItem
          button
          onClick={() => handleClick(link.title)}
          className={classes.name}
        >
          <ListItemText primary={link.title} />
          <ListItemIcon className={isCollapseOpen === link.title ? 'up' : 'down'}>
            <ArrowIcon />
          </ListItemIcon>
        </ListItem>

        <Collapse in={isCollapseOpen === link.title} timeout="auto" unmountOnExit>
          {link.items.map((item) => (
            <ListItem
              button
              component="a"
              href={item.link}
              className={classes.item}
              key={item.key}
            >
              <ListItemText primary={item.title} />
            </ListItem>
          ))}
        </Collapse>
         </>
        ))}
      </List>
    </>
  )
}

FooterMenuMobile.propTypes = {
  links: PropTypes.array.isRequired
}
