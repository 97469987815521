import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import PropTypes from 'prop-types';

export default function StyledButton ({ text, onClick, type, padding, height, width, ...rest }) {
  const theme = useTheme();

  const types = {
    dashed: {
      backgroundColor: 'transparent',
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      hover: theme.palette.white.almost
    },
    filled: {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      hover: theme.palette.primary.dark
    },
    transparent: {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
      hover: theme.palette.white.almost
    },
    main: {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      hover: theme.palette.primary.main
    },
    openCompany: {
      backgroundColor: 'transparent',
      border: `1px solid ${theme.palette.info.main}`,
      hover: theme.palette.info.soft,
      color: theme.palette.info.main

    },
    switchAccount: {
      backgroundColor: 'transparent',
      border: `1px solid ${theme.palette.success.main}`,
      hover: theme.palette.success.light,
      color: theme.palette.success.main
    }
  };

  const { backgroundColor, border, color, hover } = types[type];

  const useStyles = makeStyles(() => ({
    button: {
      backgroundColor,
      border,
      color,
      padding: padding,
      height: height,
      width: width,
      '&:hover': {
        backgroundColor: hover
      },
      '& .MuiButton-text': {
        width: 'auto'
      }
    }
  }));

  const classes = useStyles();

  return (
    <Button onClick={onClick} className={classes.button} {...rest}>
      {text}
    </Button>
  );
}

StyledButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['dashed', 'filled', 'transparent', 'main', 'openCompany', 'switchAccount']).isRequired,
  height: PropTypes.string,
  padding: PropTypes.string,
  width: PropTypes.string.isRequired
};

StyledButton.defaultProps = {
  type: 'dashed'
};
