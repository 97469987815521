import React from 'react';
import { Box, Typography, makeStyles, Grid } from '@material-ui/core';
import logo from '../../static/images/logo.svg';
import RedirectLink from '../atoms/RedirectLink';
import SocialMediaFooter from '../atoms/SocialMediaFooter';
import FooterMenuDesktop from '../molecules/FooterMenuDesktop';
import FooterMenuMobile from '../molecules/FooterMenuMobile';
import FooterLinks from '../../static/files/FooterLinks.json';

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.gray.lightGray}`,
    paddingBottom: '18px',

    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column'
    }
  },
  logo: {
    marginBottom: '16px'
  },
  helpPortal: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: '15px',

    [theme.breakpoints.down('sm')]: {
      marginTop: '5%'
    }
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.gray.midGray,

    '&:hover': {
      textDecoration: 'underline'
    }
  },
  titleTopic: {
    letterSpacing: '0.065em',
    lineHeight: '22px'
  },
  desktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  mobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'contents'
    }
  }
}));

const Footer = () => {
  const classes = useStyles();

  const { links } = FooterLinks;

  return (
    <Box>
      <Grid container direction={{ md: 'row', sm: 'column' }} className={classes.root}>
        <Grid item md={3} sm={12} className={classes.logo}>
          <img src={logo} alt="Logo da conube" className={classes.logo}/>
          <Typography>
            Contabilidade Digital com atendimento humanizado
          </Typography>
        </Grid>
        <Grid item md={6} sm={12} className={classes.desktop}>
          <FooterMenuDesktop links={links}/>
        </Grid>
        <Grid item sm={12} className={classes.mobile}>
          <FooterMenuMobile links={links}/>
        </Grid>
        <Grid item md={3} className={classes.helpPortal}>
          <Typography variant={'subtitle2'} className={classes.titleTopic}>
            Portal de ajuda
          </Typography>
          <Typography>
            <RedirectLink
              className={classes.link}
              href="https://suporte.conube.com.br/"
              toNewTab
            >
              Perguntas Frequentes
            </RedirectLink>
          </Typography>
        </Grid>
      </Grid>
      <SocialMediaFooter />
    </Box>
  );
};

export default Footer;
