import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '1220px',
    rowGap: '130px',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      rowGap: '50px',
      padding: '0 30px'
    },
  },
}));

export default function ContainerLimit({ children }) {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      {children}
    </Box>

  )
}

ContainerLimit.propTypes = {
  children: PropTypes.elementType.isRequired
}
