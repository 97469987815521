import React from 'react';
import PropTypes from 'prop-types';

const RedirectLink = ({ children, toNewTab, ...rest }) => {
  const newTabProps = toNewTab
    ? {
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : {};

  return (
    <a {...newTabProps} {...rest}>
      {children}
    </a>
  );
};

RedirectLink.defaultProps = {
  children: <></>,
  toNewTab: false,
};

RedirectLink.propTypes = {
  children: PropTypes.element,
  toNewTab: PropTypes.bool,
};

export default RedirectLink;
