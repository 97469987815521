import { Box, makeStyles, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import HappyWoman from '../../static/images/hero-image.png';
import ShowInMobile from '../atoms/ShowInMobile';
import AutoCompleteInput from '../organisms/AutoCompleteInput';

const useStyles = makeStyles((theme) => ({
  shadowBackground: {
    position: 'absolute',
    width: '100%',
  },
  container: {
    background: theme.palette.primary.dark,
    backgroundImage: 'url(/shadow-hero.svg)',
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    height: '500px',
    borderRadius: '0px 0px 24px 24px',
    [theme.breakpoints.down(350)]: {
      height: '600px'
    }
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '100px',
    margin: '0 auto',
    height: '100%',
    maxWidth: '1220px',
    width: '100%',

    [theme.breakpoints.down('md')]: {
      margin: 0,
    }
  },
  phraseInput: {
    color: theme.palette.primary.lightest,
    marginTop: 'calc((500px / 2) - 95px)',
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      padding: '0 30px',
    }
  },
  phrase: {
    width: '100%',
    lineHeight: '54px',
    color: 'white',
  },
  title: {
    color: theme.palette.primary.lightest,
    fontSize: '14px'
  },
  inputBox: {
    marginTop: '5%',
    width: '100%',
    position: 'relative',
  },
  illustration: {
    display: 'flex',
    height: '100%',
    alignItems: 'flex-end',

    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
}));

export default function Hero() {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.contentWrapper}>
          <Box className={classes.phraseInput}>
            <Typography variant="h6" component="p" className={classes.title} aria-level="1">
              TE AJUDAMOS A EMPREENDER
            </Typography>
            <Typography variant="h3" component="p" color="inherit" aria-level="2" className={classes.phrase}>
              Facilitamos a busca de atividades para sua empresa
            </Typography>
              <Box className={classes.inputBox}>
                <AutoCompleteInput />
            </Box>
          </Box>
          <Box className={classes.illustration}>
            <ShowInMobile breakpoint={theme.breakpoints.values.md}>
              <img src={HappyWoman} alt="Mulher sorrindo sentada" />
            </ShowInMobile>
          </Box>
        </Box>
      </Box>
    </>
  );
}
