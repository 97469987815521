import React from 'react';
import PropTypes from 'prop-types';

export default function ArrowLeftIcon({ color }) {
  return (
    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0.25 6C0.25 5.58579 0.585786 5.25 1 5.25H15C15.4142 5.25 15.75 5.58579 15.75 6C15.75 6.41421 15.4142 6.75 15 6.75H1C0.585786 6.75 0.25 6.41421 0.25 6Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.53033 0.46967C6.82322 0.762563 6.82322 1.23744 6.53033 1.53033L1.53033 6.53033C1.23744 6.82322 0.762563 6.82322 0.46967 6.53033C0.176777 6.23744 0.176777 5.76256 0.46967 5.46967L5.46967 0.46967C5.76256 0.176777 6.23744 0.176777 6.53033 0.46967Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M0.46967 5.46967C0.762563 5.17678 1.23744 5.17678 1.53033 5.46967L6.53033 10.4697C6.82322 10.7626 6.82322 11.2374 6.53033 11.5303C6.23744 11.8232 5.76256 11.8232 5.46967 11.5303L0.46967 6.53033C0.176777 6.23744 0.176777 5.76256 0.46967 5.46967Z" fill={color}/>
    </svg>
  )
}

ArrowLeftIcon.propTypes = {
  color: PropTypes.string
}

ArrowLeftIcon.defaultProps = {
  color: '#8645FF'
}
