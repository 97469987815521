import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '114px 0'
  }
}))

export default function BigSpacer() {
  const classes = useStyles();

  return (
    <div className={classes.container} />
  )
}
