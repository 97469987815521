import React from 'react';
import PropTypes from 'prop-types';

export default function FacebookIcon({ color }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.5 3.75C5.42921 3.75 3.75 5.42921 3.75 7.5V16.504C3.75 18.5728 5.42721 20.25 7.496 20.25H16.504C18.5729 20.25 20.25 18.5727 20.25 16.505V7.496C20.25 5.42721 18.5728 3.75 16.504 3.75H7.5ZM7.5 2.25C4.60079 2.25 2.25 4.60079 2.25 7.5V16.504C2.25 19.4012 4.59879 21.75 7.496 21.75H16.504C19.4011 21.75 21.75 19.4013 21.75 16.505V7.496C21.75 4.59879 19.4012 2.25 16.504 2.25H7.5Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M10.35 12.9C10.35 12.4858 10.6858 12.15 11.1 12.15H16.5C16.9142 12.15 17.25 12.4858 17.25 12.9C17.25 13.3142 16.9142 13.65 16.5 13.65H11.1C10.6858 13.65 10.35 13.3142 10.35 12.9Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.15 11.055C12.15 9.17479 13.6748 7.65 15.555 7.65H16.5C16.9142 7.65 17.25 7.98579 17.25 8.4C17.25 8.81421 16.9142 9.15 16.5 9.15H15.555C14.5032 9.15 13.65 10.0032 13.65 11.055V21C13.65 21.4142 13.3142 21.75 12.9 21.75C12.4858 21.75 12.15 21.4142 12.15 21V11.055Z" fill={color}/>
    </svg>

  )
}

FacebookIcon.propTypes = {
  color: PropTypes.string
}

FacebookIcon.defaultProps = {
  color: '#676482'
}
