import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Collapse, List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import StyledDrawer from './StyledDrawer';
import ArrowIcon from '../atoms/icons/ArrowIcon';
import SwitchIcon from '../atoms/icons/SwitchIcon';
import KeyIcon from '../atoms/icons/KeyIcon';
import HomeIcon from '../atoms/icons/HomeIcon';
import DrawerLinksMobile from '../atoms/DrawerLinksMobile';
import UseDrawerContext from '../../contexts/DrawerContext';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'none',
    '@media screen and (max-width: 1290px)': {
      display: 'block',
    },
  },
  list: {
    paddingTop: '40px',
    '& .MuiListItem-button': {
      borderRadius: '10px',
    },
  },
  divider: {
    width: '70%',
    height: '1px',
    transform: 'translateX(-50%)',
    margin: '20px 0 20px 50%',
    backgroundColor: theme.palette.action.disabledBackground,
  },
  customerArea: {
    '& .MuiListItemIcon-root': {
      justifyContent: 'flex-end',
    },
    '& .up svg': {
      transform: 'rotate(180deg)',
      transition: '0.1s',
    },
    '& .down svg': {
      transform: 'rotate(0deg)',
      transition: '0.1s',
    },
  },
}));

export default function HeaderDrawerMenu({ drawerOpen, setDrawerOpen }) {
  const classes = useStyles();
  const theme = useTheme();
  const [isCollapsable, setIsCollapsable] = useState(false);
  const { setOpen } = UseDrawerContext();

  function handleCreateAccount() {
    setDrawerOpen(false);
    setOpen(true);
  }

  return (
    <StyledDrawer
      open={drawerOpen}
      setOpen={setDrawerOpen}
      className={classes.root}
    >
      {/* TODO: ver com o vini se é isso mesmo */}
      <List className={classes.list}>
        <ListItem
          button
          onClick={() => setIsCollapsable(!isCollapsable)}
          className={classes.customerArea}
        >
          <ListItemText primary="Conheça a conube" />
          <ListItemIcon className={isCollapsable ? 'up' : 'down'}>
            <ArrowIcon />
          </ListItemIcon>
        </ListItem>
        <Collapse in={isCollapsable} timeout="auto" unmountOnExit>

          <DrawerLinksMobile
            text={'Acessar plataforma'}
            url={'https://app.conube.com.br/'}
            icon={<HomeIcon />}
          />
          <DrawerLinksMobile
            text={'Processo de Abertura'}
            url={'https://abrirempresa.conube.com.br/login'}
            icon={<KeyIcon />}
            color={theme.palette.info.main}
          />
          <DrawerLinksMobile
            text={'Processo de Trocar de contador'}
            url={'https://trocarcontador.conube.com.br/login'}
            icon={<SwitchIcon />}
            color={theme.palette.success.main}
          />
        </Collapse>

        <ListItem button onClick={handleCreateAccount}>
          <ListItemText primary="Abra sua empresa" />
        </ListItem>

        {/* <Box className={classes.divider} /> */}

        {/* <DrawerLinksMobile
          text={'Conube'}
          url={'https://www.conube.com.br/'}
        />

        <DrawerLinksMobile
          text={'Abrir empresa'}
          url={'https://www.conube.com.br/abrir-empresa'}
        />

        <DrawerLinksMobile
          text={'Trocar de contador'}
          url={'https://www.conube.com.br/trocar-contador'}
        />

        <DrawerLinksMobile
          text={'Soluções'}
          url={'https://www.conube.com.br/solucoes'}
        />

        <DrawerLinksMobile
          text={'Quanto custa'}
          url={'https://www.conube.com.br/quanto-custa'}
        /> */}
      </List>

    </StyledDrawer>
  );
}

HeaderDrawerMenu.propTypes = {
  drawerOpen: PropTypes.bool.isRequired,
  setDrawerOpen: PropTypes.func.isRequired,
};
