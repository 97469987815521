import React from 'react';
import PropTypes from 'prop-types';

export default function WhatsAppIcon({ color }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M2.00002 11.45C2.00002 6.23578 6.23582 2 11.45 2C14.0619 2 16.4492 3.08849 18.1804 4.81967C19.9116 6.55086 21 8.93809 21 11.55C21 16.7642 16.7642 21 11.55 21C10.0144 21 8.58216 20.6153 7.25979 19.9486L2.93933 21.0757C2.68107 21.1431 2.40647 21.0681 2.21825 20.8789C2.03003 20.6896 1.95657 20.4146 2.02534 20.1567L3.14765 15.9481C2.38576 14.5174 2.00002 12.9873 2.00002 11.45ZM11.45 3.5C7.06424 3.5 3.50002 7.06421 3.50002 11.45C3.50002 12.8141 3.86325 14.1865 4.60121 15.4779C4.69917 15.6493 4.72558 15.8525 4.6747 16.0432L3.80641 19.2993L7.16074 18.4243C7.3441 18.3765 7.53878 18.3998 7.70563 18.4896C8.91013 19.1382 10.1889 19.5 11.55 19.5C15.9358 19.5 19.5 15.9358 19.5 11.55C19.5 9.36191 18.5885 7.34914 17.1197 5.88033C15.6509 4.41151 13.6381 3.5 11.45 3.5Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.81973 13.2197C9.11263 12.9268 9.5875 12.9268 9.88039 13.2197C10.787 14.1263 11.7953 14.8448 12.7023 15.1687C13.5953 15.4876 14.2494 15.39 14.7197 14.9197C15.0126 14.6268 15.4875 14.6268 15.7804 14.9197C16.0733 15.2126 16.0733 15.6874 15.7804 15.9803C14.7507 17.01 13.4047 17.0124 12.1978 16.5813C11.0048 16.1553 9.81316 15.2738 8.81973 14.2803C8.52684 13.9874 8.52684 13.5126 8.81973 13.2197Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M13.9001 13.25C13.7762 13.1571 13.5971 13.1637 13.4804 13.2804L13.0803 13.6804C12.7874 13.9733 12.3126 13.9732 12.0197 13.6803C11.7268 13.3874 11.7268 12.9126 12.0197 12.6197L12.4197 12.2197C13.103 11.5364 14.124 11.543 14.8001 12.05C15.2068 12.355 15.5874 12.6402 15.8661 12.826C16.9351 13.5386 16.848 14.9127 16.0804 15.6803L15.6804 16.0803C15.3875 16.3732 14.9127 16.3733 14.6198 16.0804C14.3269 15.7875 14.3268 15.3126 14.6197 15.0197L15.0197 14.6197C15.1291 14.5103 15.1669 14.3855 15.1631 14.2925C15.1599 14.2108 15.1267 14.1358 15.034 14.0741C14.7168 13.8626 14.3042 13.5531 13.9153 13.2614L13.9001 13.25Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M9.02832 7.87942C8.87723 7.73709 8.62626 7.73447 8.48038 7.88035L8.08034 8.28037C7.78744 8.57325 7.31256 8.57324 7.01968 8.28034C6.7268 7.98743 6.72681 7.51256 7.01971 7.21968L7.41972 6.81969C8.26149 5.97794 9.57967 6.16145 10.2357 6.9815C10.2687 7.02275 10.2973 7.06737 10.3209 7.11461C10.3877 7.24819 10.477 7.37713 10.6023 7.53666C10.6388 7.58317 10.6856 7.64082 10.7374 7.70465C10.8354 7.82551 10.9514 7.96854 11.0501 8.10002C11.5571 8.77609 11.5638 9.797 10.8804 10.4804L10.4804 10.8804C10.1875 11.1733 9.71259 11.1732 9.4197 10.8803C9.12682 10.5874 9.12683 10.1126 9.41974 9.81968L9.81975 9.41969C9.93638 9.30304 9.943 9.12395 9.85005 9.00002C9.76223 8.88292 9.68847 8.79245 9.60817 8.69395C9.55195 8.62499 9.49252 8.5521 9.42281 8.46338C9.29112 8.29577 9.15087 8.10447 9.02832 7.87942Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.19835 7.23828C8.48098 7.5411 8.46461 8.01569 8.1618 8.29831C7.70836 8.72153 7.5891 9.33986 7.89651 10.2303C8.20847 11.1339 8.92554 12.1648 9.8804 13.1197C10.1733 13.4126 10.1733 13.8875 9.8804 14.1804C9.58751 14.4732 9.11264 14.4732 8.81974 14.1804C7.7746 13.1352 6.89167 11.9162 6.47863 10.7198C6.06103 9.51019 6.09176 8.17852 7.13832 7.20173C7.44114 6.9191 7.91573 6.93547 8.19835 7.23828Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M9.4642 9.8069C9.81939 9.59379 10.2801 9.70896 10.4932 10.0641C10.7558 10.5019 11.1149 10.9542 11.5804 11.4197C12.0458 11.8852 12.4982 12.2443 12.9359 12.5069C13.2911 12.72 13.4063 13.1807 13.1931 13.5359C12.98 13.8911 12.5193 14.0063 12.1642 13.7931C11.6018 13.4558 11.0542 13.0149 10.5197 12.4804C9.98515 11.9458 9.54433 11.3982 9.20696 10.8359C8.99384 10.4807 9.10902 10.02 9.4642 9.8069Z" fill={color}/>
    </svg>

  )
}

WhatsAppIcon.propTypes = {
  color: PropTypes.string
}

WhatsAppIcon.defaultProps = {
  color: '#676482'
}
