import { Drawer, IconButton, makeStyles } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import CloseIcon from '../atoms/icons/CloseIcon';
import PropTypes from 'prop-types';

export default function StyledDrawer({
  children,
  open,
  setOpen,
  closeIcon,
  closeOnClickOutside,
  anchor,
  variant,
  className,
  width,
  borderRadius,
  disabled,
}) {
  const useStyles = makeStyles((theme) => ({
    drawer: {
      background: theme.palette.primary.alpha,
      boxShadow: '0px 30px 70px rgba(58, 53, 104, 0.102)',
    },
    paper: {
      width,
      borderRadius,
      maxWidth: '810px',
      overflow: 'auto',
      backgroundColor: theme.palette.secondary.contrastText,
      border: 'none',
      outline: 0,
      padding: '50px 30px 30px',

      '@media screen and (max-width: 959px)': {
        width: '90%',
      },
      '@media screen and (max-width: 500px)': {
        width: '88%',
      },
      '@media screen and (max-width: 430px)': {
        width: '86%',
      },
      '@media screen and (max-width: 360px)': {
        width: '84%',
      },
    },
    buttonClose: {
      position: 'absolute',
      top: '15px',
      right: '17px',
      zIndex: '100',
    },
    icon: {
      color: theme.palette.primary.main,
      fontsize: '30px !important',
    },
  }));
  const classes = useStyles();

  return (
    <Drawer
      className={`${classes.drawer} ${className}`}
      classes={{
        paper: classes.paper
      }}
      open={open}
      onClose={() => (closeOnClickOutside ? setOpen(false) : null)}
      anchor={anchor}
      variant={variant}
    >
      {closeIcon && (
        <IconButton
          className={classes.buttonClose}
          color="primary"
          onClick={() => setOpen(false)}
          disabled={disabled}
        >
          <CloseIcon color={'#323172'} />
        </IconButton>
      )}
      {children}
    </Drawer>
  )
}

StyledDrawer.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  closeIcon: PropTypes.func,
  closeOnClickOutside: PropTypes.bool,
  anchor: PropTypes.string,
  variant: PropTypes.string,
  width: PropTypes.string,
  borderRadius: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.objectOf(String)
};

StyledDrawer.defaultProps = {
  closeIcon: true,
  closeOnClickOutside: true,
  anchor: 'right',
  variant: 'temporary',
  width: '90%',
  borderRadius: '0 !important',
  disabled: false,
  className: {}
};
