import React, { useState } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import PropTypes from 'prop-types';

export const DrawerContext = createContext({});

export function DrawerContextProvider ({ children }) {
  const [open, setOpen] = useState(false);
  const [process, setProcess] = useState('default');
  const [openByHeader, setOpenByHeader] = useState(true);

  function openProcessCreateAccount() {
    setOpen(true);
    setProcess('abrir-empresa');
    setOpenByHeader(false);
  }

  return (
    <DrawerContext.Provider
      value={{
        open,
        setOpen,
        process,
        setProcess,
        openProcessCreateAccount,
        openByHeader,
        setOpenByHeader
      }}
    >
      {children}
    </DrawerContext.Provider>
  )
}

export default function UseDrawerContext() {
  const open = useContextSelector(DrawerContext, (drawer) => drawer.open);
  const setOpen = useContextSelector(DrawerContext, (drawer) => drawer.setOpen);
  const process = useContextSelector(DrawerContext, (drawer) => drawer.process);
  const setProcess = useContextSelector(DrawerContext, (drawer) => drawer.setProcess);
  const openByHeader = useContextSelector(DrawerContext, (drawer) => drawer.openByHeader);
  const setOpenByHeader = useContextSelector(DrawerContext, (drawer) => drawer.setOpenByHeader);
  const openProcessCreateAccount = useContextSelector(DrawerContext, (drawer) => drawer.openProcessCreateAccount);

  return {
    open,
    setOpen,
    process,
    setProcess,
    openProcessCreateAccount,
    openByHeader,
    setOpenByHeader
  }
}

DrawerContextProvider.propTypes = {
  children: PropTypes.elementType.isRequired
}
