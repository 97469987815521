import React from 'react';
import { makeStyles, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles({
  progress: {
    position: 'absolute',
    top: '46.5%',
    left: '46.5%',
  },
});

export default function CenteredLoading() {
  const classes = useStyles();

  return (
    <CircularProgress className={classes.progress} />
  );
};
