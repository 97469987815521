import { Box, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import FacebookIcon from './icons/FacebookIcon';
import InstagramIcon from './icons/InstagramIcon';
import LinkedinIcon from './icons/LinkedinIcon';
import SpotifyIcon from './icons/SpotifyIcon';
import WhatsAppIcon from './icons/WhatsAppIcon';
import YoutubeIcon from './icons/YoutubeIcon';

const useStyles = makeStyles((theme) => ({
  rightsIcons: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '28px 0',

    '@media screen and (max-width: 1290px)': {
      flexDirection: 'column-reverse',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  icons: {
    display: 'flex',
    gap: '22px',
    alignItems: 'center'
  },
}))

export default function SocialMediaFooter() {
  const classes = useStyles();

  return (
    <Box className={classes.rightsIcons}>
        <Typography>
        ©2021 Conube - Todos os direitos reservados
        </Typography>
        <Box className={classes.icons}>
          <a target="_blank" rel="noopener noreferrer" href="https://open.spotify.com/show/2Vy7E4zSOVBZvYPKMqzi0N" title={'Spotify da Conube'}>
            <SpotifyIcon />
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/conubecontabilidade" title={'Instagram da Conube'}>
            <InstagramIcon />
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/conube/" title={'Facebook da Conube'}>
            <FacebookIcon />
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/user/ContabilidadeOnline" title={'Canal do Youtube da Conube'}>
            <YoutubeIcon />
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://br.linkedin.com/company/conube" title={'Linkedin da Conube'}>
            <LinkedinIcon />
          </a>
          <a target="_blank" rel="noopener noreferrer" href="#" title={'Whatsapp da Conube'}>
            <WhatsAppIcon />
          </a>
        </Box>
      </Box>
  )
}
