import React from 'react';
import PropTypes from 'prop-types';

export default function HomeIcon({ color }) {
  return (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M10.57 0.385576C10.8282 0.204808 11.1719 0.204808 11.4302 0.385576L19.4204 5.97874C19.4266 5.98295 19.4328 5.98725 19.4388 5.99164L21.4302 7.38558C21.7695 7.62311 21.852 8.09076 21.6145 8.4301C21.377 8.76943 20.9093 8.85196 20.57 8.61442L19.7501 8.04049V19C19.7501 19.4142 19.4143 19.75 19.0001 19.75H3.00006C2.58585 19.75 2.25006 19.4142 2.25006 19V8.04049L1.43016 8.61442C1.09082 8.85196 0.623176 8.76943 0.38564 8.4301C0.148105 8.09076 0.230631 7.62311 0.569968 7.38558L2.56128 5.99166C2.56737 5.98726 2.57353 5.98295 2.57975 5.97873L10.57 0.385576ZM3.75006 6.99049V18.25H7.25006V13C7.25006 11.4858 8.48585 10.25 10.0001 10.25H12.0001C13.5143 10.25 14.7501 11.4858 14.7501 13V18.25H18.2501V6.99049L11.0001 1.91549L3.75006 6.99049ZM13.2501 18.25V13C13.2501 12.3142 12.6859 11.75 12.0001 11.75H10.0001C9.31428 11.75 8.75006 12.3142 8.75006 13V18.25H13.2501Z" fill={color}/>
    </svg>

  )
}

HomeIcon.propTypes = {
  color: PropTypes.string
}

HomeIcon.defaultProps = {
  color: '#A39EB8'
}
