export const createInput = (name, type, value) => {
  const input = document.createElement('input');

  input.setAttribute('name', name);
  input.setAttribute('type', type);
  input.setAttribute('value', value);

  return input;
};

export const CreateAccountOpenCompany = (data, formName) => {
  const form = document.createElement('form');

  form.setAttribute('action', `${process.env.GATSBY_BASE_URL}/signup`);
  form.setAttribute('name', formName);
  form.setAttribute('method', 'post');
  form.setAttribute('class', process.env.GATSBY_CLASS_CADASTRO_ABRIR_EMPRESA);

  const name = createInput('name', 'text', data.name);
  const lastName = createInput('lastName', 'text', data.lastName);
  const phone = createInput('phone', 'tel', data.phone);
  const email = createInput('email', 'text', data.email);
  const password = createInput('password', 'password', data.password);

  form.appendChild(name);
  form.appendChild(lastName);
  form.appendChild(phone);
  form.appendChild(email);
  form.appendChild(password);

  const currentForm = document.getElementsByName(name)[0];
  document.body.insertBefore(form, currentForm);

  form.submit();
};
