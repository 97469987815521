import React from 'react'
import PropTypes from 'prop-types';

export default function TimeIcon({ color }) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.74219 3.4873C9.12188 3.4873 9.42969 3.79511 9.42969 4.1748V9.58505L13.6062 12.0764C13.9323 12.2709 14.039 12.6929 13.8445 13.019C13.6499 13.3451 13.2279 13.4518 12.9018 13.2572L8.38999 10.5659C8.18205 10.4419 8.05469 10.2176 8.05469 9.97547V4.1748C8.05469 3.79511 8.36249 3.4873 8.74219 3.4873Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M14.3475 3.6525C11.3942 0.699166 6.60585 0.699164 3.65251 3.65251L3.6525 3.65251C0.699166 6.60583 0.699164 11.3941 3.65251 14.3475C6.60583 17.3008 11.3941 17.3008 14.3475 14.3475C17.3007 11.3943 17.3008 6.60619 14.3478 3.65282C14.3477 3.65272 14.3476 3.65261 14.3475 3.6525ZM15.3197 2.68024C18.8101 6.17055 18.8101 11.8295 15.3197 15.3197C11.8294 18.8101 6.17053 18.8101 2.68023 15.3197C-0.810077 11.8294 -0.810079 6.17053 2.68024 2.68023C6.17055 -0.810077 11.8295 -0.810079 15.3197 2.68024Z" fill={color}/>
    </svg>

  )
}

TimeIcon.propTypes = {
  color: PropTypes.string
}

TimeIcon.defaultValue = {
  color: '#573DA8'
}
