import React from 'react';
import PropTypes from 'prop-types';

export default function SwitchIcon({ color }) {
  return (
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.53033 2.46967C6.82322 2.76256 6.82322 3.23744 6.53033 3.53033L5.03033 5.03033C4.73744 5.32322 4.26256 5.32322 3.96967 5.03033C3.67678 4.73744 3.67678 4.26256 3.96967 3.96967L5.46967 2.46967C5.76256 2.17678 6.23744 2.17678 6.53033 2.46967Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M3.96967 0.96967C4.26256 0.676777 4.73744 0.676777 5.03033 0.96967L6.53033 2.46967C6.82322 2.76256 6.82322 3.23744 6.53033 3.53033C6.23744 3.82322 5.76256 3.82322 5.46967 3.53033L3.96967 2.03033C3.67678 1.73744 3.67678 1.26256 3.96967 0.96967Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M0.25 6C0.25 3.88579 1.88579 2.25 4 2.25C4.41421 2.25 4.75 2.58579 4.75 3C4.75 3.41421 4.41421 3.75 4 3.75C2.71421 3.75 1.75 4.71421 1.75 6C1.75 6.41421 1.41421 6.75 1 6.75C0.585786 6.75 0.25 6.41421 0.25 6Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M3.25 3C3.25 2.58579 3.58579 2.25 4 2.25H6C6.41421 2.25 6.75 2.58579 6.75 3C6.75 3.41421 6.41421 3.75 6 3.75H4C3.58579 3.75 3.25 3.41421 3.25 3Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M16.0303 16.9697C16.3232 17.2626 16.3232 17.7374 16.0303 18.0303L14.5303 19.5303C14.2374 19.8232 13.7626 19.8232 13.4697 19.5303C13.1768 19.2374 13.1768 18.7626 13.4697 18.4697L14.9697 16.9697C15.2626 16.6768 15.7374 16.6768 16.0303 16.9697Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M13.4697 18.4697C13.7626 18.1768 14.2374 18.1768 14.5303 18.4697L16.0303 19.9697C16.3232 20.2626 16.3232 20.7374 16.0303 21.0303C15.7374 21.3232 15.2626 21.3232 14.9697 21.0303L13.4697 19.5303C13.1768 19.2374 13.1768 18.7626 13.4697 18.4697Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M19 15.25C19.4142 15.25 19.75 15.5858 19.75 16C19.75 18.1142 18.1142 19.75 16 19.75C15.5858 19.75 15.25 19.4142 15.25 19C15.25 18.5858 15.5858 18.25 16 18.25C17.2858 18.25 18.25 17.2858 18.25 16C18.25 15.5858 18.5858 15.25 19 15.25Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M13.25 19C13.25 18.5858 13.5858 18.25 14 18.25H16C16.4142 18.25 16.75 18.5858 16.75 19C16.75 19.4142 16.4142 19.75 16 19.75H14C13.5858 19.75 13.25 19.4142 13.25 19Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M1.25 13.5C1.25 10.0858 4.08579 7.25 7.5 7.25C10.9142 7.25 13.75 10.0858 13.75 13.5C13.75 16.9142 10.9142 19.75 7.5 19.75C5.44675 19.75 3.63541 18.7252 2.50676 17.2589C1.69952 16.2202 1.25 14.8567 1.25 13.5ZM7.5 8.75C4.91421 8.75 2.75 10.9142 2.75 13.5C2.75 14.5426 3.10003 15.5784 3.69202 16.3395L3.69448 16.3427C4.56592 17.4756 5.95399 18.25 7.5 18.25C10.0858 18.25 12.25 16.0858 12.25 13.5C12.25 10.9142 10.0858 8.75 7.5 8.75Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M15.8697 4.06968C16.1626 3.77678 16.6375 3.77678 16.9304 4.06968C19.3299 6.46919 19.3126 10.435 16.9425 12.9179L16.9304 12.9303C16.8585 13.0021 16.7901 13.0616 16.7335 13.109C16.7104 13.1284 16.6873 13.1474 16.6678 13.1634C16.6641 13.1665 16.6605 13.1695 16.6571 13.1723C16.6341 13.1912 16.6164 13.2059 16.6006 13.2194C16.5791 13.2378 16.5665 13.2499 16.5599 13.2565C16.3684 13.6115 15.928 13.7525 15.5646 13.5708C15.1941 13.3856 15.044 12.9351 15.2292 12.5646C15.3476 12.3277 15.5157 12.1737 15.6244 12.0806C15.676 12.0363 15.7133 12.0058 15.7439 11.9809C15.7906 11.9427 15.8215 11.9174 15.8639 11.8754C17.6874 9.95791 17.6681 6.92871 15.8697 5.13034C15.5768 4.83744 15.5768 4.36257 15.8697 4.06968Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.08212 4.05748C10.565 1.68745 14.5308 1.67016 16.9304 4.06968C17.2232 4.36257 17.2232 4.83743 16.9304 5.13032C16.6375 5.42322 16.1626 5.42323 15.8697 5.13034C14.0713 3.33196 11.0421 3.31258 9.12457 5.13609C9.08261 5.17852 9.0573 5.20947 9.01911 5.25616C8.99414 5.2867 8.96366 5.32397 8.91942 5.37559C8.82625 5.48429 8.67223 5.65239 8.43539 5.77081C8.0649 5.95606 7.6144 5.80589 7.42915 5.4354C7.24746 5.07202 7.38845 4.63164 7.74352 4.44011C7.75011 4.43348 7.76213 4.42087 7.78053 4.3994C7.79407 4.38361 7.80876 4.36591 7.82767 4.34294C7.83048 4.33952 7.83342 4.33595 7.83646 4.33225C7.85252 4.31274 7.87154 4.28963 7.89096 4.26647C7.93836 4.20995 7.99783 4.14148 8.06965 4.06966L8.08212 4.05748Z" fill={color}/>
    </svg>

  )
}

SwitchIcon.propTypes = {
  color: PropTypes.string
}
SwitchIcon.defaultProps = {
  color: '#48ae9f'
}
