import React, { useState } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import PropTypes from 'prop-types';

export const CnaeContext = createContext({});

const FILTERS_DEFAULT_VALUE = {
  permitido: false,
  impedido: false,
  ambiguo: false
};

export function CnaeContextProvider ({ children }) {
  const [cnaes, setCnaes] = useState([]);
  const [currentCnae, setCurrentCnae] = useState(false);
  const [valueInput, setValueInput] = useState('');
  const [paginationParams, setPaginationParams] = useState({});
  const [filters, setFilters] = useState(FILTERS_DEFAULT_VALUE);

  function submitInput(response, inputValue) {
    const { cnaes, page, limit, total, totalPages, status } = response;

    setCnaes(cnaes);
    setFilters(FILTERS_DEFAULT_VALUE);
    setValueInput(inputValue);
    setPaginationParams({ page, limit, total, totalPages, status });
  }

  function getOption(cnae, inputValue) {
    setCnaes([cnae]);
    setFilters(FILTERS_DEFAULT_VALUE);
    setCurrentCnae(cnae);
    setValueInput(inputValue);
    setPaginationParams({ page: 1, limit: 1, total: 1, totalPages: 1 });
  }

  function applyFilter(response) {
    const { cnaes, page, limit, total, totalPages, status } = response;

    setCnaes(cnaes);
    setPaginationParams({ page, limit, total, totalPages, status });
  }

  return (
    <CnaeContext.Provider
      value={{
        setCnaes,
        setCurrentCnae,
        setValueInput,
        getOption,
        submitInput,
        applyFilter,
        setFilters,
        paginationParams,
        filters,
        valueInput,
        cnaes,
        currentCnae,
      }}
    >
      {children}
    </CnaeContext.Provider>
  );
}

export default function useCnaeContext() {
  const cnaes = useContextSelector(CnaeContext, (cnae) => cnae.cnaes);
  const setCnaes = useContextSelector(CnaeContext, (cnae) => cnae.setCnaes);
  const currentCnae = useContextSelector(CnaeContext, (cnae) => cnae.currentCnae);
  const setCurrentCnae = useContextSelector(CnaeContext, (cnae) => cnae.setCurrentCnae);
  const setValueInput = useContextSelector(CnaeContext, (cnae) => cnae.setValueInput);
  const valueInput = useContextSelector(CnaeContext, (cnae) => cnae.valueInput);
  const getOption = useContextSelector(CnaeContext, (cnae) => cnae.getOption);
  const submitInput = useContextSelector(CnaeContext, (cnae) => cnae.submitInput);
  const paginationParams = useContextSelector(CnaeContext, (cnae) => cnae.paginationParams);
  const setPaginationParams = useContextSelector(CnaeContext, (cnae) => cnae.setPaginationParams);
  const applyFilter = useContextSelector(CnaeContext, (cnae) => cnae.applyFilter);
  const filters = useContextSelector(CnaeContext, (cnae) => cnae.filters);
  const setFilters = useContextSelector(CnaeContext, (cnae) => cnae.setFilters);

  return {
    cnaes,
    setCnaes,
    currentCnae,
    setCurrentCnae,
    setValueInput,
    valueInput,
    getOption,
    submitInput,
    setPaginationParams,
    paginationParams,
    applyFilter,
    filters,
    setFilters
  };
}

CnaeContextProvider.propTypes = {
  children: PropTypes.elementType.isRequired
};
