import React from 'react';
import PropTypes from 'prop-types';

export default function InstagramIcon({ color }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.496 3H16.505C18.987 3 21 5.012 21 7.496V16.505C21 18.987 18.988 21 16.504 21H7.496C5.013 21 3 18.988 3 16.504V7.496C3 5.013 5.012 3 7.496 3V3Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.9493 6.71289C16.7633 6.71389 16.6123 6.86489 16.6123 7.05089C16.6123 7.23689 16.7643 7.38789 16.9503 7.38789C17.1363 7.38789 17.2873 7.23689 17.2873 7.05089C17.2883 6.86389 17.1363 6.71289 16.9493 6.71289Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.546 9.45432C15.9519 10.8602 15.9519 13.1396 14.546 14.5455C13.1401 15.9514 10.8607 15.9514 9.45481 14.5455C8.04892 13.1396 8.04892 10.8602 9.45481 9.45432C10.8607 8.04843 13.1401 8.04843 14.546 9.45432" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}

InstagramIcon.propTypes = {
  color: PropTypes.string
}

InstagramIcon.defaultProps = {
  color: '#676482'
}
