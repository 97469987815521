import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

export default function DrawerLinksMobile({ text, url, icon, color }) {
  return (
    <ListItem button component="a" href={url}>
      {icon && (
        <ListItemIcon>
          {icon}
        </ListItemIcon>
      )}
      <ListItemText
        primary={text}
        primaryTypographyProps={{ style: { color } }} />
    </ListItem>
  )
}

DrawerLinksMobile.propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  icon: PropTypes.elementType,
}
