import { Box, Grow, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import logo from '../../static/images/logo.svg';
import IconMobileMenu from '../../static/images/icon-mobile-menu.svg';
import StyledButton from '../atoms/StyledButton';
import HeaderDropDownMenu from '../molecules/HeaderDropDownMenu';
import HeaderDrawerMenu from '../molecules/HeaderDrawerMenu';
import CreateAccountDrawer from '../organisms/CreateAccountDrawer';
import UseDrawerContext from '../../contexts/DrawerContext';
import { Link } from 'gatsby';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    position: 'fixed',
    zIndex: 1000,
    left: '50%',
    top: 0,
    transform: 'translateX(-50%)',
    display: 'flex',
    justifyContent: 'center',
  },
  base: {
    height: '75px',
    width: '100%',
    maxWidth: '1460px',
    margin: '20px 30px 0',
    padding: '0 40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.contrastText,
    boxShadow: '0px 8px 11px rgba(0, 0, 0, 0.05)',
    borderRadius: '100px',
    position: 'relative',

    '&:before': {
      content: 'close-quote',
      width: '94%',
      position: 'absolute',
      margin: '0 auto',
      bottom: 0,
      padding: '1px',
      background: `linear-gradient(90deg, rgba(84,196,179,1) 0%,
      rgba(84,172,223,1) 5%,
      rgba(142,72,152,1) 10%,
      rgba(222,106,46,1) 15%,
      rgba(187,59,74,1) 20%,
      rgba(84,196,179,1) 25%,
      rgba(84,172,223,1) 30%,
      rgba(142,72,152,1) 35%,
      rgba(222,106,46,1) 40%,
      rgba(187,59,74,1) 45%,
      rgba(84,196,179,1) 50%,
      rgba(84,172,223,1) 55%,
      rgba(142,72,152,1) 60%,
      rgba(222,106,46,1) 65%,
      rgba(187,59,74,1) 70%,
      rgba(222,106,46,1) 75%,
      rgba(84,196,179,1) 80%,
      rgba(84,172,223,1) 85%,
      rgba(142,72,152,1) 90%,
      rgba(222,106,46,1) 95%,
      rgba(187,59,74,1) 100%)`
    },

    [theme.breakpoints.down('md')]: {
      borderRadius: 0,
      margin: 0,
      maxWidth: '100%',
      padding: '0 30px',

      '&:before': {
        width: '100%',
        margin: '0'
      }
    }
  },
  bodyHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '1220px',
    width: '100%'
  },
  accountsButton: {
    display: 'flex',
    gap: '20px',
    width: '40%',
    '& .MuiButton-label': {
      fontSize: '12px',
      textTransform: 'inherit',
    },
    '& .MuiButton-text': {
      padding: '10px',
      width: '140px',
    }
  },

  mobileMenuButton: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    }
  },
  [theme.breakpoints.down('md')]: {
    accountsButton: { display: 'none' },
  },
}));

export default function Header () {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState();
  const { setOpen, setOpenByHeader } = UseDrawerContext();
  const [menuAnchor, setMenuAnchor] = useState(null);

  function openDrawer() {
    setOpen(true);
    setOpenByHeader(true);
  }

  return (
    <header className={classes.container}>
      <Grow in timeout={300}>
        <Box className={classes.base}>
          <Box className={classes.bodyHeader}>
            <Link to="/">
              <img src={logo} alt="Logo da Conube" />
            </Link>
            <Box className={classes.accountsButton}>
              <StyledButton
                text="CONHEÇA A CONUBE"
                type="transparent"
                onClick={(e) => setMenuAnchor(e.currentTarget)}
                height="52px"
                width="100% !important"
              />
              <StyledButton
                text="ABRA A SUA EMPRESA"
                type="filled"
                onClick={() => openDrawer()}
                width="100% !important"
              />

              <HeaderDropDownMenu
                menuAnchor={menuAnchor}
                setMenuAnchor={setMenuAnchor}
              />
            </Box>
            <IconButton onClick={() => setDrawerOpen(true)} className={classes.mobileMenuButton}>
              <img src={IconMobileMenu} alt="Menu de opções" />
            </IconButton>
          </Box>
          <HeaderDrawerMenu
            setDrawerOpen={setDrawerOpen}
            drawerOpen={drawerOpen}
          />

          <CreateAccountDrawer />
        </Box>
      </Grow>
    </header>
  );
};
