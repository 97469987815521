import React from 'react';
import { TextField } from '@material-ui/core';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';

const MaskedTextField = ({ value, textFieldProps, ...rest }) => {
  return (
    <InputMask maskChar="" {...rest}>
      {(inputProps) => (
        <TextField {...inputProps} {...textFieldProps} value={value} />
      )}
    </InputMask>
  );
};

MaskedTextField.defaultProps = {
  textFieldProps: {},
};

MaskedTextField.propTypes = {
  value: PropTypes.string.isRequired,
  textFieldProps: PropTypes.shape(),
};

export default MaskedTextField;
