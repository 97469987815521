import React, { useState } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  TextField,
  FormHelperText,
  Typography,
  MenuItem,
  Box,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import axios from 'axios';

import PasswordTextField from '../atoms/PasswordTextField';
import MaskedTextField from '../molecules/MaskedTextField';
import AutocompleteCityUF from '../organisms/AutoCompleteCityUF';
import { SegmentOptions } from '../functions/Options';
import {
  EmailValidation,
  NameValidation,
  LastNameValidation,
  PhoneValidation,
  CnpjValidation,
  ObjectValidation,
  LengthValidation,
  PasswordValidation,
} from '../functions/FormValidation';
import AllowedCities from '../../static/files/AllowedCities.json';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',

    '& .MuiStepper-root': {
      padding: '20px 0 0',
    },
    '& .MuiFormControl-root': {
      width: '70%',

      '@media screen and (max-width: 959px)': {
        width: '100%',
      },
    },
    '& .MuiFormHelperText-root': {
      color: theme.palette.error.main,
      marginBottom: '20px',
    },
  },
  inputWrap: {
    display: 'grid',
    marginBottom: '30px',
    gap: '15px',
  },
  submitButtons: {
    display: 'flex',
  },
}));

function renderStepLabel (text) {
  return (
    <StepLabel>
      <Typography variant="body1">{text}</Typography>
    </StepLabel>
  );
};

export default function SwitchAccountantFormDrawer({ isLoading, setIsLoading }) {
  const classes = useStyles();
  const [error, setError] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const name = 'Trocar Contador | Lead Drawer';
  const [data, setData] = useState({});

  function handleNext() {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  function handleBack() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function validateFields() {
    return (
      ObjectValidation(data, 9) &&
      LengthValidation(data) &&
      NameValidation(data) &&
      LastNameValidation(data) &&
      PhoneValidation(data) &&
      EmailValidation(data) &&
      CnpjValidation(data) &&
      PasswordValidation(data)
    );
  };

  function isFormValid() {
    try {
      return validateFields();
    } catch (e) {
      return false;
    }
  }

  function validateForm() {
    try {
      if (validateFields()) {
        setError({});
      }
    } catch (e) {
      setError({ field: e.fileName, message: e.message });
      return false;
    }
    return true;
  };

  async function sendDataToHubspot() {
    const portalId = process.env.GATSBY_HUBSPOT_PORTAL_ID;
    const formGuid = process.env.GATSBY_HUBSPOT_TROCAR_CONTADOR_FORM_GUID;
    const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`;

    const dataHubspot = {
      fields: [
        {
          name: 'email',
          value: data.email,
        },
        {
          name: 'firstname',
          value: data.name,
        },
        {
          name: 'lastname',
          value: data.lastName,
        },
        {
          name: 'segmento_atuacao',
          value: data.segment,
        },
        {
          name: 'phone',
          value: data.phone,
        },
        {
          name: 'cidade_estado',
          value: `${data.cityName} - ${data.state}`,
        },
        {
          name: 'cnpj',
          value: data.cnpj,
        },
      ],
      context: {
        pageUri: window.location.href,
        pageName: document.title,
      },
    };

    try {
      const cloudfare = await axios.get('https://www.cloudflare.com/cdn-cgi/trace');
      if (cloudfare.data) {
        const ipInfo = cloudfare.data.split('\n')[2];
        if (ipInfo.indexOf('ip=') > -1) {
          dataHubspot.context.ipAddress = ipInfo.replace('ip=', '');
        }
      }

      await axios.post(url, dataHubspot);
    } catch (err) {
      setError({ message: 'Desculpe, ocorreu um erro. Tente novamente mais tarde!' });
    }
  };

  async function handleSubmit(event) {
    event.preventDefault();

    if (validateForm()) {
      setIsLoading(true);
      await sendDataToHubspot();

      const isAllowedCity = AllowedCities.cities.find((city) => city.name === data.cityName) !== undefined;

      if (isAllowedCity) {
        const registerData = {
          email: data.email,
          nome: data.name,
          senha: data.password,
          telefone: data.phone,
        };

        try {
          const response = await axios.post(`${process.env.GATSBY_API_BASE_URL}/api/migrar-empresa/novo-usuario`, registerData);

          if (response?.status === 200) {
            const { access_token: accessToken, refresh_token: refreshToken } = response.data;
            window.location = `${process.env.GATSBY_MIGRAR_EMPRESA_BASE_URL}/login/site/${accessToken}/${refreshToken}`;
          }
        } catch (err) {
          setIsLoading(false);
          setError({ field: '', message: 'Desculpe, ocorreu um erro. Tente novamente mais tarde!' });
        }
      } else {
        setIsLoading(false);
        setError({ message: 'No momento, estamos atendendo apenas as cidades de São Paulo - SP' });
      }
    }
  };

  function changeData(value, field) {
    setData({ ...data, [field]: value });
    setError({});
  };

  function handleCityUFChange(value) {
    if (value) {
      setData({
        ...data,
        state: value.stateInitial,
        city: value.code,
        cityName: value.name,
      });
    } else {
      setData({
        ...data,
        state: null,
        city: null,
        cityName: null,
      });
    }
    setError({});
  };

  function renderButtons() {
    return (
      <div>
        <Button
          color="primary"
          onClick={handleBack}
          disabled={activeStep === 0 || isLoading}
        >
          Voltar
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleNext}
          disabled={isLoading}
        >
          Avançar
        </Button>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <form
        name={name}
        onSubmit={handleSubmit}
        className={process.env.GATSBY_CLASS_CADASTRO_TROCAR_CONTADOR}
      >
        <Typography variant="body1">Trocar de contador na Conube é rápido e online.</Typography>

        <Stepper activeStep={activeStep} orientation="vertical">
          <Step>
            {renderStepLabel('Informações pessoais')}

            <StepContent>
              <Box className={classes.inputWrap}>
                <TextField
                  label="Nome"
                  name="name"
                  value={data.name}
                  onChange={(e) => changeData(e.target.value, 'name')}
                  error={error.field === 'name'}
                  disabled={isLoading}
                />
                <TextField
                  label="Sobrenome"
                  name="lastName"
                  value={data.lastName}
                  onChange={(e) => changeData(e.target.value, 'lastName')}
                  error={error.field === 'lastName'}
                  disabled={isLoading}
                />
                <MaskedTextField
                  onChange={(e) => changeData(e.target.value, 'phone')}
                  mask="+99 (99) 99999-9999"
                  textFieldProps={{
                    label: 'Telefone',
                    name: 'phone',
                    type: 'tel',
                    floating: true,
                    value: data.phone,
                    error: error.field === 'phone',
                  }}
                  InputProps={{
                    disabled: isLoading
                  }}
                />
              </Box>

              {renderButtons()}
            </StepContent>
          </Step>

          <Step>
            {renderStepLabel('Informações da sua empresa')}

            <StepContent>
              <Box className={classes.inputWrap}>
                <TextField
                  label="Qual segmento de atuação?"
                  name="segmento_atuacao"
                  value={data.segment}
                  onChange={(e) => changeData(e.target.value, 'segment')}
                  options={SegmentOptions}
                  disabled={isLoading}
                  select
                >
                  {SegmentOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <AutocompleteCityUF
                  onChange={(value) => handleCityUFChange(value)}
                  value={{
                    stateInitial: data.state,
                    code: data.city,
                    name: data.cityName,
                  }}
                  disabled={isLoading}
                />
                <MaskedTextField
                  onChange={(e) => changeData(e.target.value, 'cnpj')}
                  mask="99.999.999/9999-99"
                  textFieldProps={{
                    label: 'CNPJ',
                    name: 'cnpj',
                    type: 'text',
                    value: data.cnpj,
                    floating: true,
                    error: error.field === 'cnpj',
                  }}
                  InputProps={{
                    disabled: isLoading
                  }}
                />
              </Box>

              {renderButtons()}
            </StepContent>
          </Step>

          <Step>
            {renderStepLabel('Cadastro')}

            <StepContent>
              <Box className={classes.inputWrap}>
                <TextField
                  label="E-mail"
                  name="email"
                  value={data.email}
                  onChange={(e) => changeData(e.target.value, 'email')}
                  error={error.field === 'email'}
                  disabled={isLoading}
                />
                <PasswordTextField
                  label="Senha"
                  name="password"
                  value={data.password}
                  onChange={(e) => changeData(e.target.value, 'password')}
                  error={error.field === 'password'}
                  disabled={isLoading}
                />
              </Box>

              <FormHelperText>{error.message}</FormHelperText>

              <div className={classes.submitButtons}>
                <Button
                  onClick={handleBack}
                  color="primary"
                  disabled={activeStep === 0 || isLoading}
                >
                  Voltar
                </Button>

                <Box onClick={validateForm}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={!isFormValid() || isLoading}
                  >
                    Criar conta
                  </Button>
                </Box>
              </div>
            </StepContent>
          </Step>
        </Stepper>
      </form>
    </div>
  );
}

SwitchAccountantFormDrawer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired
};
